@import "modules/responsive-type.scss";
@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,700');
$primary: #823ba4;
/* MAIN COLOR */

$secondary: #91368b;
/* SECONDARY COLOR */

$blk: #000;
$wht: #fff;
// Set your maximum and minimum screen sizes.
$min_width: 320px;
$max_width: 1920px;
// Font sizes will range between the $min_font and $max_font.
$min_font: 15px;
$max_font: 22px;
// Change these values to change the "scale" between different headers
// (h1,h2,h3, etc.). Larger numbers = larger font-sizes. 
$mod_1: 1.2; // mobile
$mod_2: 1.5; // desktop

html,
body {
    height: 100%;
    box-sizing: border-box;
    font-family: 'Raleway', sans-serif;
    font-weight: lighter;
}

html {
    @include fluid-type($min_width,
    $max_width,
    $min_font,
    $max_font);
}

p {
    font-size: .8rem;
}

h1 {
    font-size: $mod_1*$mod_1*$mod_1*$mod_1 *1rem;
    @include fluid-type($min_width,
    $max_width,
    $mod_1*$mod_1*$mod_1 *$min_font,
    $mod_2*$mod_2*$mod_2 *$min_font);
}

h2 {
    font-size: $mod_1*$mod_1*$mod_1 *1rem;
    @include fluid-type($min_width,
    $max_width,
    $mod_1*$mod_1*$mod_1 *$min_font,
    $mod_2*$mod_2*$mod_2 *$min_font);
}

h3 {
    font-size: $mod_1*$mod_1 *1rem;
    @include fluid-type($min_width,
    $max_width,
    $mod_1*$mod_1 *$min_font,
    $mod_2*$mod_2 *$min_font);
}

body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

q,
blockquote {
    quotes: "“""”";
}

a,
a:hover,
a:focus,
a:active {
    outline: 0 !important;
    text-decoration: none;
}

.navbarFixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}

.logo {
    max-height: 100px;

    @media(max-width: 767px) {
        max-height: 70px;
    }
}

/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #0065dd;
    -webkit-text-fill-color: #0065dd;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}

nav.navbar {
    z-index: 1000;
    border: none;

    .navbar-nav {
        .nav-link {
            text-align: center;
            align-items: center;
            color: $blk;
            border-radius: 5px;
            font-size: .8rem;

            @media (max-width: 767px) {
                margin-top: 0;
                padding: 6px 0px;
                display: inline-block;
                text-align: center;
            }

            &:hover {
                background: $primary;
                color: $wht;

                @media (max-width: 1024px) {
                    background: transparent;
                    outline: 0;
                    color: $blk;
                }
            }

            &:focus,
            &:active {
                background: transparent;
                color: $blk;
                outline: 0;
            }
        }
    }
}

@mixin btn {
    border: none;
    background: darken($primary, 15%);
    color: $wht;
    padding: 0.5em 2em;
    font-size: .9rem;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    margin: .5em 0em;
    transition: 0.7s ease all;
    border-radius: 50px;

    &:hover {
        background: lighten($primary, 10%);
        color: lighten($wht, 10%);
        text-decoration: none;

        @media (max-width: 1024px) {
            background: $secondary;
            color: $wht;
            box-shadow: 0px 0px 5px $blk;
        }
    }
}

.btn-default {
    @include btn;
}

span {
    font-weight: bold
}

.bg {
    background-attachment: fixed;
    background-size: cover;
    background-position: top center;
    padding: 200px 0;

    @media(max-width: 1024px) {
        background-attachment: scroll;
        padding: 150px 0;
    }

    @media(max-width:768px) {
        padding: 100px 0;
    }

    @media(max-width:600px) {
        padding: 50px 0;
    }
}

.pd {
    padding: 150px 0;

    @media(max-width: 1024px) {
        padding: 100px 0;
    }

    @media(max-width:767px) {
        padding: 50px 0;
    }
}

// img {
//     @include border-radius(5px);
// }

section {
    @extend .pd;

    h3 {
        font-weight: bold;
        text-transform: uppercase;
    }
}

.main-banner {
    background-color: $primary;
    // background-image: linear-gradient(to bottom, rgba($primary, 1), rgba($primary, .0)), url(../img/waves.svg);
    background-size: 100%;
    background-repeat: repeat-x;
    background-position: bottom;
   @extend .pd;
    color: $wht;

    h2 {
        font-size: 1.7em;
    }


    

}

.about-section {
    img {
        // padding: 20px;
        border-radius: 5px;
        margin-bottom: 20px;
    }

    .about-desc {
        margin-bottom: 60px;
    }
}

.icon-block {
    background-color: darken($wht, 5%);

    .services-desc {
        margin-bottom: 60px;
    }

    .icon-box {
        padding: 50px;
        position: relative;

        // border: .5px solid $primary;

        i {
            margin-bottom: 20px;
            color: $primary;
            position: relative;
            z-index: 2;
            transition: all ease-in 0.5s;
        }

        h4 {
            font-size: 1.2em;
            font-weight: bold;
            position: relative;
            z-index: 2;
            transition: all ease-in 0.5s;
        }

        p {
            color: $blk;
            z-index: 2;
            position: relative;
            transition: all ease-in 0.5s;
        }

        // &:hover {

        //     h4,
        //     i,
        //     p {
        //         color: $wht;

        //     }

        //     &::before {
        //         width: 100%;
        //     }
        // }

        // &::before {
        //     width: 0;
        //     height: 100%;
        //     background-color: $primary;
        //     content: "";
        //     display: block;
        //     position: absolute;
        //     left: 0;
        //     top: 0;
        //     transition: all cubic-bezier(0.39, 0.59, 0, 0.68) 0.5s;
        //     z-index: 1;

        // }

    }
}

.contact {
    background-color:lighten($primary, 50%);
    .contact-desc {
        margin-bottom: 50px;
    }

    li {
        margin-bottom: 20px;
        ;
    }

    a {

        color: $blk;

        &:hover {
            text-decoration: underline;
            color: darken($primary, 10%);
        }
    }
}


// here starts the footer 
footer {
    padding: 50px 0px 20px;
    background: $primary;
    color: $wht;
    font-size: 15px;

    a {
        color: $wht;
        white-space: nowrap;
        font-size: 15px;

        @media (max-width: 990px) {
            font-size: 13px;
        }

        &:hover {
            color: lighten($wht, 10%);
            outline: 0 !important;

        }

        &:focus {
            color: $wht;
            outline: 0 !important;

        }
    }

    p {
        font-size: 15px;

        @media (max-width: 990px) {
            font-size: 13px;
        }
    }
}

.top-pad {
    padding: 3em;
    background: white;

    @media (max-width: 990px) {
        padding: 1em;
    }
}

.terms,
.privacy {
    p {
        font-size: 1rem;
    }

    & ol {
        li {
            font-size: 1rem;
        }

        & ol li {
            font-size: 0.85rem;
        }
    }
}